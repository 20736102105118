import React from 'react';
import PageButton from './PageButton';
import { getIconByName } from '../js/utils';
import '../css/PlayingModePickingHeader.css'
import '../css/DevicePickingHeader.css'

function PlayingModePickingHeader(props) {
    return ( 
        <div className='playing-mode-picking-header'>
                <div className='page-buttons-container'>
                        {Object.keys(props.data).filter(k => {
                            return props.data[k][props.currentDevice] !== undefined
                        }).map(d => <PageButton key={d} name={d} id={"playing-mode-page-button-" + d} icon={getIconByName(d, 'page-icon')} current={props.currentPlayingMode} setCurrent={props.setCurrentPlayingMode}/>)}
                </div>
            </div>
    );
}

export default PlayingModePickingHeader;