import React from 'react'

function PageButton(props) {
    return ( 
        <div id={props.id} className={'page-button' + (props.current === props.name ? ' active' : '')} onClick={() => props.setCurrent(props.name)}>
            {props.icon}
        </div>
     );
}

export default PageButton;