import React, { useEffect, useState } from 'react'
import { ChevronDownIcon } from '@primer/octicons-react'
import '../css/CollapsibleSection.css'
import { useLocation } from 'react-router-dom'
import { calculateInitialStateForSidebarSection } from '../js/utils'

function SidebarSection(props) {
    const [sectionState, setSectionState] = useState(props.initialState || false)
    const location = useLocation()

    useEffect(() => {
        setSectionState(calculateInitialStateForSidebarSection(props.title))
    }, [location, props.title])

    return ( 
        <div className={"sidebar-section" + (sectionState ? " open" : " closed")}>
            <div className={"sidebar-section-header collapsible-section-header" + (props.title === "Dashboard" ? ' display-none' : '')}
            onClick={() => {
                setSectionState(!sectionState)
            }}>
                {props.title}
                <ChevronDownIcon className='collapsible-section-arrow' />
            </div>
            <div className="collapsible-section-content-wrapper">
                <div className="sidebar-section-link-container collapsible-section-content">
                    {props.children}
                </div>
            </div>
        </div>
     );
}

export default SidebarSection;