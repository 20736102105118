import React, { useEffect, useState } from 'react'
import '../css/TestTable.css'
import { STAT_NICKNAMES, STAT_UNITS } from '../js/const'
import { getIconByName } from '../js/utils'
import { filterRelevantHeaders } from '../js/dataUtils'
import { Link } from 'react-router-dom'

function TestTable(props) {
    const [headings, setHeadings] = useState([])
    const [rowData, setRowData] = useState([])
    // const [prevRowData, setPrevRowData] = useState([])

    useEffect(() => {
        const newRowData = filterRelevantHeaders(props.data?.[props.device][props.currentTestTimestamp], ['Map', 'Position'])
        setRowData(newRowData)
        setHeadings(Object.keys(newRowData[0] || []))
        // setPrevRowData(props.data?.[props.device][props.previousTestTimestamp])
    }, [props.currentTestTimestamp, props.device, props.data, props.previousTestTimestamp])

    const header = (
    <thead>
        <tr>
            {headings.map((heading) => {
                const to = (heading === 'Checkpoint') ? `${props.playingMode}/locations?device=${props.device}` : `${props.playingMode}/stats?stat=${heading}&device=${props.device}`
                return <th key={heading}>
                    <Link to={to} className="test-table-header-link test-table-link">
                        <div className='test-table-th-title'>{getIconByName(heading)}{STAT_NICKNAMES[heading] || heading}</div>
                        {STAT_NICKNAMES[heading] ? 
                        <div className="test-table-th-subtitle">{"(" + heading + ")"}</div>
                        : null}
                    </Link>
                </th>
            })}
        </tr>
    </thead>
    )

    const rows = (
        <tbody>
            {rowData.map((data) => {
                const rowCheckpoint = data.Checkpoint
                return (
                    <tr key={props.device + ': ' + data.Checkpoint}>
                        {Object.entries(data).map(([key, value]) => {
                            const to = (key === 'Checkpoint') ? `${props.playingMode}/locations?location=${rowCheckpoint}&device=${props.device}` : `${props.playingMode}/stats?stat=${key}&location=${rowCheckpoint}&device=${props.device}`
                            return <td key={key}>
                                    <Link className='test-table-link' to={to}>
                                        {value + (STAT_UNITS[key] || '')}
                                    </Link>
                                </td>
                        })}
                    </tr>
                )
            })}
        </tbody>
    )

    return ( <div>
        <table className='test-table' id={props.id || ""} >
            {header}
            {rows}
        </table>
    </div> );
}

export default TestTable;