import React, { useState } from 'react'
import { getCheckpointToMapMap, getUniqueCheckpointsForSpecificMap, getUniqueMaps,  } from '../js/dataUtils';
import CollapsibleSection from './CollapsibleSection';
import { useSearchParams } from 'react-router-dom';
import LocationStatsContainer from './LocationStatsContainer';
import DevicePickingHeader from './DevicePickingHeader';
import { getIconByName } from '../js/utils';


function LocationsPage(props) {
    const [searchParams] = useSearchParams()
    const [currentDevice, setCurrentDevice] = useState(searchParams.get('device') || "PC")


    return ( 
        <div id="locations-page">
            <DevicePickingHeader title={"Locations"} currentDevice={currentDevice} setCurrentDevice={setCurrentDevice} data={props.data}/>            
            <div className="content-container">
                {getUniqueMaps(props.data, currentDevice).map(mapName =>
                    <CollapsibleSection key={mapName} title={mapName} icon={getIconByName('Map')} className={'big-header'}
                    initialState={getCheckpointToMapMap(props.data, currentDevice)[searchParams.get('location')] === mapName}>
                        {getUniqueCheckpointsForSpecificMap(props.data, currentDevice, mapName).map((cp) => 
                            <CollapsibleSection key={cp} title={cp} className={'thin-header'} icon={getIconByName('Locations')}
                            initialState={searchParams.get('location') === cp}>
                                <LocationStatsContainer data={props.data} currentDevice={currentDevice} searchParams={searchParams} cp={cp} />
                            </CollapsibleSection>
                        )}
                    </CollapsibleSection>
                )}
            </div>
        </div>
     );
}

export default LocationsPage;