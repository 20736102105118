import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';

function DashWidget(props) {
    const [showInfo, setShowInfo] = useState(false)

    const getDashWidgetLinkDestination = (data, playingMode, device, title, params) => {
        switch (title) {
            case 'Tests saved':
                return `${playingMode}/tests?device=${params[1]}&test=${params[0]}`;
            case 'Locations':
                return `${playingMode}/locations?location=${params[0]}`;
            case 'Stats':
                return `${playingMode}/stats?stat=${params[0]}`;
            default:
                return null;
        }
    }

    return ( 
        <div className={"dash-widget" + (showInfo ? " showing-info" : "")} onClick={(e) => {
                if (!e.target.classList.contains('dash-widget-info-item'))
                    setShowInfo(!showInfo)
            }}>
            { !showInfo ? 
                <div className="dash-widget-value">
                    {props.content ? props.content : props.value.length}
                    {props.centerText ? <div className="dash-widget-center-text">{props.centerText}</div> : null}
                </div>
            : <div className='dash-widget-info'>
                {props.value.map(v => <NavLink key={v} to={getDashWidgetLinkDestination(props.data, props.currentPlayingMode, props.currentDevice, props.title, [v, props.currentDevice])} className='dash-widget-info-item'>{v}</NavLink>)}
            </div>
            }
            <div className={"dash-widget-title" + (props.content && !showInfo ? " content-title" : "")}>
                {props.title}
            </div>
        </div>
     );
}

export default DashWidget;