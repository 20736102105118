import React from 'react'
import '../css/WorstCaseWidget.css'
import { STAT_NICKNAMES, STAT_UNITS } from '../js/const';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { getWorstCaseData, filterDataForSpecificMap, getMaxStatValue } from '../js/dataUtils';
import CollapsibleSection from './CollapsibleSection';
import { useNavigate } from 'react-router-dom';
import { getTargetColor } from '../js/utils';

function WorstCaseWidget(props) {
    let worst = []

    const navigate = useNavigate()

    const handleWorstCaseChartPointClick = (e, activeElements, chart) => {
        if (activeElements.length !== 1)
            return
        
        const clickedPointIndex = activeElements[0].index

        navigate(`${props.currentPlayingMode}/locations?location=${worst[clickedPointIndex][0]}&stat=${worst[clickedPointIndex][2]}`)
    }

    const getWorstCaseChart = (data, device, stat, mapName) => {
        const input = filterDataForSpecificMap(data, device, mapName)
        worst = getWorstCaseData(input, device, stat).reverse().slice(-7)
        
        return (
            <Line data={{
                labels: worst.map(a => a[0]),
                datasets: [{
                    label: stat,
                    data: worst.map(a => a[1])
                }]
            }}  options={{
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    }
                },
                scales:
                {
                    x: {
                        ticks: {
                            display: false
                        },
                        title: {
                            text: 'Last 7 Tests',
                            display: true
                        }
                    },
                    y: {
                        beginAtZero: true,
                        max: getMaxStatValue(data, device, stat),
                } 
                },
                onClick: handleWorstCaseChartPointClick

            }}/>
        )
    }

    return ( 
        <div className={'worst-case-widget ' + getTargetColor(props.title, props.value)}>
            <div className="worst-case-label">
                <div className="worst-case-title">
                    {props.icon}
                    {STAT_NICKNAMES[props.title]}
                </div>
                <div className="worst-case-value">
                    {props.value + STAT_UNITS[props.title]}
                </div>
                <div className="worst-case-subtitle">
                    {props.subtitle}
                </div>   
                <CollapsibleSection title={"History"} className='centered-header'>
                    <div className="worst-case-chart">
                        {getWorstCaseChart(props.data, props.device, props.title, props.mapName)}
                    </div>
                </CollapsibleSection>         
            </div>
        </div>
     );
}

export default WorstCaseWidget;