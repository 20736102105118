export const TARGET = {
    // key refers to minimum value for the color (0-24, 25-29, 30+)
    "GPUTime": {
        "0": "green",
        "25": "yellow",
        "30": "red"
    },
    "GameThreadTime":
    {
        "0": "green",
        "14": "yellow",
        "16": "red" 
    },
    "MemoryUsed":
    {
        "0": "green",
        "5000": "yellow",
        "7000": "red"
    }
}
export const IGNORED_STATS = [
    "Map",
    "Checkpoint",
    "Position"
]

export const STAT_NICKNAMES = {
    "GPUTime": "GPU",
    "GameThreadTime": "CPU",
    "MemoryUsed": "RAM",
    "Checkpoint": "Location"
}

export const STAT_UNITS = {
    "GPUTime": "ms",
    "GameThreadTime": "ms",
    "MemoryUsed": "MB",
    "DrawCalls": "",
    "PrimitivesDrawn": "",
}

export const HIGH_IS_BETTER = {
    DrawCalls: false,
    GPUTime: false,
    GameThreadTime: false,
    MemoryUsed: false,
    PrimitivesDrawn: false,
}

export const P4_CHANGES_DISPLAYED_NUM = 20;