// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDJ3khQPCEM2OuyVdh0LJjGdEW69TKCMlY",
  authDomain: "towers-perf-tests-c6de0.firebaseapp.com",
  projectId: "towers-perf-tests-c6de0",
  storageBucket: "towers-perf-tests-c6de0.appspot.com",
  messagingSenderId: "741027862339",
  appId: "1:741027862339:web:9cfcf66231cbc5e39bc1f6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const provider = new GoogleAuthProvider()
provider.setCustomParameters({
  'login_hint': 'user@raccoonlogic.com'
});

export const auth = getAuth(app)

