import React, { useState } from 'react'
import { GoChevronDown } from 'react-icons/go'
import '../css/CollapsibleSection.css'

function CollapsibleSection(props) {
    const [sectionState, setSectionState] = useState(props.initialState || false)
    return ( 
        <div id={props.id || ""} className={"collapsible-section" + (sectionState ? " open" : " closed") + (props.className ? (' ' + props.className) : '')}>
            <div className="collapsible-section-header" onClick={() => {
                    setSectionState(!sectionState)
                }}>
                {props.icon}
                <h3>
                    {props.title}
                </h3>
                {props.subtitle ? <div className="collapsible-section-header-subtitle">{"(" + props.subtitle + ")"} {(props.closedSubtitle && !sectionState) ? (<span> - <b>{props.closedSubtitle}</b></span>) : null}</div> : null}
                <GoChevronDown className='collapsible-section-arrow'/>
            </div>
            <div className="collapsible-section-content-wrapper">
                <div className={(props.className ? props.className : "") + " collapsible-section-content"}>
                    {props.children}
                </div>
            </div>
        </div>
     );
}

export default CollapsibleSection;