import React from 'react'
import '../css/SignInPage.css'
import GoogleIcon from '../res/google-icon.svg'
import { signIntoGoogle } from '../firebase/firebaseUtils';
import raccoonLogo from '../res/raccoon-logo.png';

function SignInPage(props) {
    return ( 
        <div id='sign-in-page'>
            <div id='sign-in-header'>
                <h1 id='sign-in-header-title'><img id='sign-in-header-logo' src={raccoonLogo} alt="" /> Raccoon Logic</h1>
                <h2 id='sign-in-header-subtitle'>Towers Perf Stats<br/>Dashboard</h2>
            </div>
            <div id='sign-in-box'>
                <h2>Sign In</h2>
                <div id="sign-in-with-google-button" onClick={() => signIntoGoogle(props.setSignedIn, props.setCurrentUser, props.setData)}>
                    <img id='google-icon' src={GoogleIcon} alt="" />
                    Continue with Google
                </div>
            </div>
        </div>
     );
}

export default SignInPage;