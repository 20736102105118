import { signInWithPopup, GoogleAuthProvider, setPersistence, browserLocalPersistence } from "firebase/auth";
import { CSVtoJSON, parseP4Info, sleep } from "../js/utils";
import { auth, provider, storage } from "./firebase";


export async function fetchUrlText(url) {

    try {
        const response = await fetch(url);
        if (response.ok) { 
            return await response.text();
        } 
    } catch (error) {
        console.error(error)
    }

    return "";
}

export async function convertFileAtUrltoList(url) {
    var text = await fetchUrlText(url);
    var lines = text.split('\n').filter(Boolean);
    return lines;
}

export function getListURL(path) {
    return "https://queries.raccoonlogic.com/listdownloadablefiles?raw&perf&target=PerformanceTests\\" + path.replace(/\//g, '\\');
}

export function getDownloadURL(path)
{
    return "https://queries.raccoonlogic.com/requestdownload?perf&target=PerformanceTests\\" + path.replace(/\//g, '\\');
}

export async function listAllInRemoteSubdirectory(path)
{
    const url = getListURL(path)
    return await convertFileAtUrltoList(url)
}

export async function getPlayingModes()
{
    const modes = await listAllInRemoteSubdirectory('');
    return modes
}

export async function getDevices(playingMode) {
    const devices = await listAllInRemoteSubdirectory(playingMode + '/');
    return devices
}

export async function getLatestTimestampForChangelist(playingMode, device, changelist) {
    let matches = []
    const tests = await listAllInRemoteSubdirectory(`${playingMode}/${device}/`).catch()
    var reversSortedTests = tests.sort().reverse()
    for(let test of reversSortedTests)
    {
        if(test.startsWith(changelist))
        {
            return test;
        }
    }
}

export async function getInfoForChangelistAsComponents(playingMode, device, changelist) {
    const timestamp = await getLatestTimestampForChangelist(playingMode, device, changelist)

    const url = getDownloadURL(`${playingMode}/${device}/${timestamp}/p4.txt`)
    const p4Info = await fetchUrlText(url);
    return parseP4Info(p4Info)
}

export async function getTestTimestampsByDevice(playingMode, device) {
    const directories = await listAllInRemoteSubdirectory(`${playingMode}/${device}`);
    // sort array by name
    // directories is a list of strings, so we can use the built-in sort method
    const sortedArray = directories.sort();
    return sortedArray.reverse()
}

export async function getTestStats(playingMode, device, timestamp) {
    const url = getDownloadURL(`${playingMode}/${device}/${timestamp}/stats/allstats.csv`)
    const text = await fetchUrlText(url);
    return CSVtoJSON(text)
}

export async function getAllTestStatsForDevice(playingMode, device) {
    const loadingText = document.getElementById('loading-text') || document.createElement('h3') // create dummy element to change text on because I don't want to check if it exists every time
    const allStats = {}
    const testTimestamps = await getTestTimestampsByDevice(playingMode, device)
    for (let ts of testTimestamps) {
        loadingText.innerText = `Downloading data...\n[${playingMode} ${device}]\n[${ts}]`
        await getTestStats(playingMode, device, ts).then((singleTestJSON) => {
            allStats[ts] = singleTestJSON
        })
    }

    return allStats
}

export async function getAllData() {
    
    const loadingText = document.getElementById('loading-text') || document.createElement('h3') // create dummy element to change text on because I don't want to check if it exists every time
    loadingText.innerText = `Reading playing modes...`
    
    const playingModes = await getPlayingModes();
    const allData = {}
    let totalJobs = 0;
    let jobsDone = 0;

    let devicesByPlayingMode = {}
    
    loadingText.innerText = `Reading devices of each game mode...`

    for (let mode of playingModes) // quickly get total job count
    {
        devicesByPlayingMode[mode] = await getDevices(mode)
        totalJobs += devicesByPlayingMode[mode].length
    }

    let allFutures = {}

    for (let playingMode of playingModes)
    {
        allFutures[playingMode] = {}

        for (let device of devicesByPlayingMode[playingMode]) {
            loadingText.innerText = `Queuing data download...\n[${playingMode} ${device}]`
            await sleep(50);
            allFutures[playingMode][device] = getAllTestStatsForDevice(playingMode, device);
        }
    }

    for (let playingMode in allFutures)
    {
        allData[playingMode] = {}
        for (let device in allFutures[playingMode])
        {
            loadingText.innerText = `Downloading data...\n[${playingMode} ${device}]`
            
            await allFutures[playingMode][device].then((deviceData) => {
                allData[playingMode][device] = deviceData
                document.querySelector(':root').style.setProperty('--loading-circle-progress', `${jobsDone++ / totalJobs * 90 + 10}%`)
                loadingText.innerText = `Downloading data...\n[${playingMode} ${device}]`
            })

            await sleep(75);
        }
    }

    document.querySelector(':root').style.setProperty('--loading-circle-progress', `${jobsDone / totalJobs * 90 + 10}%`)
    await sleep(50) // let it show the fully loaded circle :)

    return allData
}

export function signIntoGoogle(setSignedIn, setCurrentUser, setData) {
    provider.setCustomParameters({
        'hd': 'raccoonlogic.com'
    });

    setPersistence(auth, browserLocalPersistence)
    .then(() =>
        signInWithPopup(auth, provider)
    )
    .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        
        // The signed-in user info.
        const user = result.user;
        
        setSignedIn(true)
        setCurrentUser(user)
        getAllData("singleplayer").then(setData)
    })
    .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.warn(errorCode, errorMessage, email, credential)
        
        setSignedIn(false)
        setCurrentUser({})
        setData({})
    })
}

export function signOut(setSignedIn, setCurrentUser, setData) {
    auth.signOut()
    .then(() => {
        setSignedIn(false)
        setCurrentUser({})
        setData({})
    })

}