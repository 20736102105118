import React from 'react'
import '../css/Sidebar.css'
import SidebarSection from './SidebarSection';
import SidebarLink from './SidebarLink';
import raccoonLogo from '../res/raccoon-logo.png';
import { calculateInitialStateForSidebarSection, getIconByName } from '../js/utils';
import { signOut } from '../firebase/firebaseUtils';
import { FeedPersonIcon } from '@primer/octicons-react';
import { useNavigate } from 'react-router-dom';

function Sidebar(props) {
    const sidebarSections = {
        "Dashboard": [{
            "title": "Dashboard",
            "href": "/",
        }],
        "Single Player": [{
            "title": "Tests",
            "href": "singleplayer/tests",
        }, {
            "title": "Locations",
            "href": "singleplayer/locations",
        }, {
            "title": "Stats",
            "href": "singleplayer/stats",
        }],
        "Split Screen": [{
            "title": "Tests",
            "href": "splitscreen/tests",
        }, {
            "title": "Locations",
            "href": "splitscreen/locations",
        }, {
            "title": "Stats",
            "href": "splitscreen/stats",
        }],
    }

    const navigate = useNavigate();

    const sidebarItems = Object.keys(sidebarSections).map((section) => {
        return (
            <SidebarSection key={section} title={section} initialState={calculateInitialStateForSidebarSection(section)}>
                {sidebarSections[section].map((item) => {
                    return <SidebarLink key={item.title} icon={getIconByName(item.title)} to={item.href} title={item.title}/>
                })}
            </SidebarSection>
        )
    })


    return (
    <div id='sidebar' className={'sidebar-' + props.sidebarState}>
        <div>
            <div id="sidebar-header" onClick={() => {
            navigate("/")
        }}>
                <img id="sidebar-logo" src={raccoonLogo} alt="" />
                <h3>Towers<br/>Perf Tests</h3>
            </div>
            {sidebarItems}
        </div>

        <div id="sign-out-sidebar-link-container">
            <div id="current-user">
                <FeedPersonIcon id='current-user-icon' />
                {props.currentUser.displayName}
            </div>
            <SidebarLink icon={getIconByName('Sign Out')} title={"Sign Out"} to='/' id={'sign-out-button'} onClick={() => {
                signOut(props.setSignedIn, props.setCurrentUser, props.setData)
            }} />
        </div>
    </div> );
}

export default Sidebar;