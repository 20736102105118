import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Content from './Content'
import SignInPage from './components/SignInPage';
import { auth } from './firebase/firebase';
import { getAllData } from './firebase/firebaseUtils';

function App() {
    const [signedIn, setSignedIn] = useState(false)
    const [currentUser, setCurrentUser] = useState({})
    const [data, setData] = useState({})

    useEffect(() => {
        if (signedIn)
        getAllData().then(setData)
        else
            setData({})
    }, [signedIn])

    useEffect(() => {
        auth.onAuthStateChanged((a) => {
            if (a === null) {
                setSignedIn(false)
                setCurrentUser({})
            } else {
                setSignedIn(true)
                setCurrentUser(a)
            }
        })
    }, [])
    
    return ( 
    <BrowserRouter>
        {signedIn ? 
            <Content signedIn={signedIn} setSignedIn={setSignedIn} currentUser={currentUser} setCurrentUser={setCurrentUser} data={data} setData={setData}/>
        : 
            <Routes>
                <Route path='*' element={<SignInPage setSignedIn={setSignedIn} setCurrentUser={setCurrentUser} setData={setData}/>}/>
            </Routes>
        }
    </BrowserRouter> );
}

export default App;