import React, { useState } from 'react'
import '../css/Dashboard.css'
import DashWidget from './DashWidget';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import CollapsibleSection from './CollapsibleSection';
import { filterDataForSpecificMap, getSpecificLastTestTimestamp, getTestCount, getUniqueCheckpoints, getUniqueMaps, getUniqueRelevantStats, getWorstCases } from '../js/dataUtils';
import WorstCaseWidget from './WorstCaseWidget';
import TestTable from './TestTable';
import { getIconByName, getDeviceColor } from '../js/utils';
import { LocationIcon } from '@primer/octicons-react';
import { BsTable } from 'react-icons/bs';
import DevicePickingHeader from './DevicePickingHeader';
import PlayingModePickingHeader from './PlayingModePickingHeader';

function Dashboard(props) {
    const [currentDevice, setCurrentDevice] = useState("PC")
    const [currentPlayingMode, setCurrentPlayingMode] = useState("singleplayer")

    const createTotalTestsChartData = (data, device) => {
        let chartData = [Object.keys(data?.[device]).length]
        let otherCount = 0
        Object.keys(data).filter(k => k !== device).forEach(d => {
            otherCount += Object.keys(data?.[d]).length
        })
        chartData.push(otherCount)

        return chartData
    }

    const getTestsChart = (data, device) => {
        return (
            <Doughnut data={{
                labels: [device, "Other"],
                datasets: [{
                    data: createTotalTestsChartData(data, device),
                    hoverOffset: 15,
                    backgroundColor: [getDeviceColor(device), "#ccc"]
                }]
                }} options={{
                    plugins: {
                        legend: {
                            display: false
                        }},
                        layout: {
                            padding: 15,
                        }
            }}/>
        )
    }

    return ( 
        <div id='dashboard-container'>
            <div>
                <div className="dashboard-picker-header">
                    <DevicePickingHeader title={"Dashboard"} currentDevice={currentDevice} setCurrentDevice={setCurrentDevice} data={props.data?.[currentPlayingMode]}/>            
                    <PlayingModePickingHeader currentDevice={currentDevice} currentPlayingMode={currentPlayingMode} setCurrentPlayingMode={setCurrentPlayingMode} data={props.data}/>
                </div>
                <div id='dashboard-content' className='content-container'>
                    {/* <DashWidget value={Object.keys(props.data?.[currentPlayingMode])} title="Consoles" /> */}
                    <DashWidget data={props.data?.[currentPlayingMode]} currentPlayingMode={currentPlayingMode} value={getUniqueCheckpoints(props.data?.[currentPlayingMode], currentDevice)} title="Locations" currentDevice={currentDevice} />
                    <DashWidget data={props.data?.[currentPlayingMode]} currentPlayingMode={currentPlayingMode} value={getTestCount(props.data?.[currentPlayingMode], currentDevice)} content={getTestsChart(props.data?.[currentPlayingMode], currentDevice)} centerText={Object.keys(props.data?.[currentPlayingMode]?.[currentDevice]).length} title={"Tests saved"} currentDevice={currentDevice} />                
                    <DashWidget data={props.data?.[currentPlayingMode]} currentPlayingMode={currentPlayingMode} value={getUniqueRelevantStats(props.data?.[currentPlayingMode], currentDevice)} title="Stats" currentDevice={currentDevice} />                
                </div>
            </div>
            <div>
                <h2>Worst Case {`(as of ${getSpecificLastTestTimestamp(props.data?.[currentPlayingMode], currentDevice)})`}</h2>
                <div id='dashboard-worst-case-content'>
                        {getUniqueMaps(props.data?.[currentPlayingMode], currentDevice).map(map => {
                            return <CollapsibleSection key={map} initialState={true} icon={<LocationIcon />} title={map}>
                                <div className="worst-case-section-container">
                                    {Object.entries(getWorstCases(props.data?.[currentPlayingMode], currentDevice, map)).map(([key, value]) => {
                                        return (
                                            <WorstCaseWidget key={key} data={props.data?.[currentPlayingMode]} currentPlayingMode={currentPlayingMode} title={key} subtitle={value.Checkpoint} value={value.Stat}
                                            icon={getIconByName(key)} device={currentDevice} mapName={map} />
                                            )
                                        })}
                                </div>

                                <CollapsibleSection title={"Full Test"} icon={<BsTable />}>
                                    <TestTable currentTestTimestamp={getSpecificLastTestTimestamp(props.data?.[currentPlayingMode], currentDevice)} data={filterDataForSpecificMap(props.data?.[currentPlayingMode], currentDevice, map, getSpecificLastTestTimestamp(props.data?.[currentPlayingMode], currentDevice))} playingMode={currentPlayingMode} device={currentDevice}/>
                                </CollapsibleSection>
                            </CollapsibleSection>
                        })}
                </div>
            </div>
        </div>
     );
}

export default Dashboard;
