import React, { useState } from 'react'
import '../css/StatsPage.css'
import { getCheckpointToMapMap, getUniqueMaps, getUniqueRelevantStats } from '../js/dataUtils';
import CollapsibleSection from './CollapsibleSection';
import { STAT_NICKNAMES } from '../js/const';
import DevicePickingHeader from './DevicePickingHeader';
import { getIconByName } from '../js/utils';
import StatStatsContainer from './StatsStatsContainer';
import { useSearchParams } from 'react-router-dom';

function StatsPage(props) {
    const [searchParams] = useSearchParams()
    const [currentDevice, setCurrentDevice] = useState(searchParams.get('device') || "PC")

    return (
        <div id="stats-page">
            <DevicePickingHeader title={"Stats"} currentDevice={currentDevice} setCurrentDevice={setCurrentDevice} data={props.data}/>            
            <div className="content-container">
                {getUniqueRelevantStats(props.data, currentDevice).map(stat => 
                    <CollapsibleSection key={stat} initialState={searchParams.get('stat') === stat} title={STAT_NICKNAMES[stat] || stat} subtitle={STAT_NICKNAMES[stat] ? stat : ''} className='big-header' icon={getIconByName(stat)}>
                        {getUniqueMaps(props.data, currentDevice).map(mapName =>
                            <CollapsibleSection initialState={getCheckpointToMapMap(props.data, currentDevice)[searchParams.get('location')] === mapName} key={stat + ': ' + mapName} title={mapName} icon={getIconByName('Map')} className={'thin-header'}>
                                <StatStatsContainer data={props.data} mapName={mapName} currentDevice={currentDevice} stat={stat} searchParams={searchParams} />
                            </CollapsibleSection>    
                        )}
                    </CollapsibleSection>
                )}
            </div>
        </div>

     );
}

export default StatsPage;