import React, { useEffect, useState } from 'react';
import DevicePickingHeader from './DevicePickingHeader';
import { getUniqueCLs } from '../js/dataUtils';
import CollapsibleSection from './CollapsibleSection';
import { getIconByName } from '../js/utils';
import TestTable from './TestTable';
import { useSearchParams } from 'react-router-dom';
import { getInfoForChangelistAsComponents } from '../firebase/firebaseUtils';
import '../css/TestsPage.css'

function TestsPage(props) {
    const [searchParams] = useSearchParams()
    const [currentDevice, setCurrentDevice] = useState(searchParams.get('device') || 'PC')
    const [clData, setClData] = useState({})

    useEffect(() => {
        getUniqueCLs(props.data, currentDevice).forEach(async (cl) => {
            if (clData[cl + "-" + currentDevice]) // reduce calls to firebase by caching repeated data for each device's CLs
            return;

            await getInfoForChangelistAsComponents("singleplayer", currentDevice, cl)
            .then((components) => {
                setClData(old => {
                    return {
                        ...old, 
                        [cl + "-" + currentDevice]: components
                    }
                })
            })

        })

    }, [clData, currentDevice, props.data])

    return ( 
        <div id="tests-page">
            <DevicePickingHeader title={'Tests'} currentDevice={currentDevice} setCurrentDevice={setCurrentDevice} data={props.data} />            
            <div className="content-container">
                {Object.keys(props.data?.[currentDevice]).map((timestampWithCL, index) => 
                {
                    const cl = timestampWithCL.split("@")[0]
                    return (
                        <CollapsibleSection key={timestampWithCL} initialState={searchParams.get('test') ? searchParams.get('test') === timestampWithCL : index === 0}
                        title={timestampWithCL} icon={getIconByName('Tests')} className={'big-header'}>

                            <div className="change-list-description">
                                {
                                    clData[cl + "-" + currentDevice]
                                } 
                            </div>


                            <TestTable key={currentDevice + "-table: " + timestampWithCL} data={props.data} playingMode={props.playingMode} device={currentDevice} currentTestTimestamp={timestampWithCL} />
                        </CollapsibleSection>    
                    )
                }
                )}
            </div>
        </div>
     );
}

export default TestsPage;