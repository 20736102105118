import React, { useState } from 'react'
import '../css/BaseLayout.css'
import '../css/Fonts.css'
import Sidebar from './Sidebar';

function BaseLayout(props) {
    const [sidebarState, setSidebarState] = useState("inline")
    return ( 
        <div>
            <div id='main-content'>
                <Sidebar sidebarState={sidebarState} setSidebarState={setSidebarState} signedIn={props.signedIn} setSignedIn={props.setSignedIn} currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} setData={props.setData}/>
                <div id='layout-body'>
                {/* <header>Performance Tests</header> */}
                    {props.children}
                </div>
            </div>
        </div>
        );
}

export default BaseLayout;