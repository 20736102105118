import React from 'react'

function LoadingView() {
    return ( 
        <div id='loading-view'>
            <div id="loading-circle">
                <div id="loading-circle-cover"></div>
            </div>
            <h3 id="loading-text">Fetching data...</h3>
        </div>
     );
}

export default LoadingView;