import React from 'react'
import { Route, Routes } from 'react-router-dom';
import BaseLayout from './components/BaseLayout';
import Dashboard from './components/Dashboard';
import LoadingView from './components/LoadingView';
import LocationsPage from './components/LocationsPage';
import StatsPage from './components/StatsPage';
import TestsPage from './components/TestsPage';

function Content(props) {


    return ( 
        <BaseLayout signedIn={props.signedIn} setSignedIn={props.setSignedIn} currentUser={props.currentUser} setCurrentUser={props.setCurrentUser} setData={props.setData}>
        {   Object.keys(props.data).length > 0 ? 
                <Routes>
                <Route path="*" element={<Dashboard data={props.data} />} />

                <Route path="singleplayer/tests" element={<TestsPage data={props.data?.["singleplayer"]} playingMode={"singleplayer"} />} />
                <Route path="singleplayer/locations" element={<LocationsPage data={props.data?.["singleplayer"]} playingMode={"singleplayer"} />} />
                <Route path="singleplayer/stats" element={<StatsPage data={props.data?.["singleplayer"]} playingMode={"singleplayer"} />} />

                <Route path="splitscreen/tests" element={<TestsPage data={props.data?.["splitscreen"]} playingMode={"splitscreen"} />} />
                <Route path="splitscreen/locations" element={<LocationsPage data={props.data?.["splitscreen"]} playingMode={"splitscreen"} />} />
                <Route path="splitscreen/stats" element={<StatsPage data={props.data?.["splitscreen"]} playingMode={"splitscreen"} />} />
            </Routes>                
            :
             <LoadingView />}
        </BaseLayout>
     );
}

export default Content;