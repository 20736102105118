import React from 'react'
import { NavLink } from 'react-router-dom'

function SidebarLink(props) {
    return ( 
            <NavLink to={props.to} id={props.id || ''} className="sidebar-link" draggable={false} onClick={props.onClick}>
                {props.icon}
                {props.title}    
            </NavLink>                    
     );
}

export default SidebarLink;