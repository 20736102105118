import React, { useState } from 'react'
import 'chart.js/auto'
import { Line } from 'react-chartjs-2';
import { filterDataBySpecifiedStats, filterDataForSpecificCheckpoint, getMaxStatValue, getUniqueCheckpointsForSpecificMap } from '../js/dataUtils'
import { getIconByName } from '../js/utils';

function StatStatsContainer(props) {
    const [selectedCheckpoint, setSelectedCheckpoint] = useState(props.searchParams.get('location'))

    const getStatStatChart = (data, device, stat, checkpoint) => {
        const relevantData = filterDataBySpecifiedStats(filterDataForSpecificCheckpoint(data, device, checkpoint), device, [stat])
        return <Line data={{
            labels: Object.keys(relevantData[device]).reverse(),
            datasets: [{
                label: checkpoint,
                data: Object.values(relevantData[device]).map(t => t[0]?.[stat]).reverse()
            }]
        }} options={{
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                }
            },
            layout: {
                padding: 20
            },
            scales: {
                y: {
                    beginAtZero: true,
                    max: getMaxStatValue(data, device, stat),
                }
            }
        }} />
        }

    return ( 
        <div className="stats-container">
            {getUniqueCheckpointsForSpecificMap(props.data, props.currentDevice, props.mapName).map((cp) =>
                <div id={`${props.stat}`} className={"stats-chart" + (selectedCheckpoint === cp ? ' selected-chart' : '')}
                key={props.stat + ': ' + cp} onClick={() => {
                    if (selectedCheckpoint === cp) {
                        setSelectedCheckpoint(null)
                    } else {
                        setSelectedCheckpoint(cp)
                    }
                }}>
                <h3 className='stats-chart-header'>{getIconByName('Locations')}{cp}</h3>
                    {getStatStatChart(props.data, props.currentDevice, props.stat, cp)}
                </div>
            )}
        </div>
     );
}

export default StatStatsContainer;