import React from 'react';
import PageButton from './PageButton';
import { getIconByName } from '../js/utils';
import '../css/DevicePickingHeader.css'

function DevicePickingHeader(props) {
    return ( 
        <div id={props.title.toLowerCase().split(' ').join('-') + "-header"} className='device-picking-header'>
                <h1>{props.title}</h1>
                <div className='page-buttons-container'>
                        {Object.keys(props.data).map(d => <PageButton key={d} name={d} icon={getIconByName(d, 'page-icon')} current={props.currentDevice} setCurrent={props.setCurrentDevice}/>)}
                </div>
            </div>
    );
}

export default DevicePickingHeader;