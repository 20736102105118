import { HIGH_IS_BETTER, IGNORED_STATS } from "./const"

export function getUniqueCheckpoints(data, device) {
    return Array.from(new Set(Object.values(data?.[device]).flat().map(x => x.Checkpoint)))
}
export function getCheckpointToMapMap(data, device) {
    const output = {}
    Object.values(data?.[device]).flat().forEach(x => { output[x.Checkpoint] =  x.Map})
    return output

}

export function getTestCount(data, device) {
    return Object.keys(data?.[device])
}

export function getUniqueRelevantStats(data, device) {
    return Array.from(new Set(Object.values(data?.[device]).flat().flatMap(x => Object.keys(x)))).filter(s => !IGNORED_STATS.includes(s))
}

export function getUniqueMaps(data, device) {
    return Array.from(new Set(Object.values(data?.[device]).flat().map(x => x.Map)))
}

export function getWorstCases(data, device, map) {
    let lastTestObj = data?.[device][getSpecificLastTestTimestamp(data, device)].filter(o => o.Map === map) 
    let worstCases = {
        "GPUTime": undefined,
        "GameThreadTime": undefined,
        "MemoryUsed": undefined
    }

    for (let stat of Object.keys(worstCases)) {
        let cp = ""
        let worst = HIGH_IS_BETTER[stat] ? Number.MAX_SAFE_INTEGER : 0
        for (let test of lastTestObj) {
            if (HIGH_IS_BETTER[stat]) {
                if (Number(test[stat]) < Number(worst)) {
                    worst = test[stat]
                    cp = test.Checkpoint
                }
            } else {
                if (Number(test[stat]) > Number(worst)) {
                    worst = test[stat]
                    cp = test.Checkpoint
                }
            }
        }

        worstCases[stat] = {
            'Checkpoint': cp,
            'Stat': worst
        }
    }

    return worstCases
}

export function getSpecificLastTestTimestamp(data, device) {
    return Object.keys(data?.[device])[0]
}


export function getOverallLastTestTitle(data) {
    let device = ""
    let timestamp = 0
    let date = 0

    Object.entries(data).forEach(([dev, obj]) => {
        let timestamps = Object.keys(obj)
        // use new Date(Date(ts)) to compare dates
        // save device and timestamp of newest date
        timestamps.forEach(ts => {
            if (new Date(Date(ts)) > date) {
                device = dev
                timestamp = ts
                date = new Date(Date(ts))
            }
        })
    })

    return [device, timestamp]

}

export function filterRelevantHeaders(testResultArray, filterOutHeaders) {
    const out = []

    testResultArray.forEach(obj => {
        const result = {}
        Object.entries(obj).forEach(([key, value]) => {
            if (!filterOutHeaders.includes(key))
                result[key] = value
        })
        out.push(result)
    })
    return out
}

export function filterDataForSpecificMap(data, device, mapName, timestamp) {
    const output = {
        [device]: {}
    }
    
    if (timestamp) {
        output[device][timestamp] = data?.[device][timestamp].filter(testObj => testObj.Map === mapName)
    } else {
        Object.entries(data?.[device]).forEach(([tsKey, testArray]) => {
            const outTestArray = testArray.filter(testObj => testObj.Map === mapName)
            if (outTestArray.length > 0)
            {
                output[device][tsKey] = outTestArray
            }
        })
    }

    return output
}

export function filterDataForSpecificCheckpoint(data, device, checkpoint, timestamp) {
    const output = {
        [device]: {}
    }
    
    if (timestamp) {
        output[device][timestamp] = data?.[device][timestamp].filter(testObj => testObj.Checkpoint === checkpoint)
    } else {
        Object.entries(data?.[device]).forEach(([tsKey, testArray]) => {
            const outTestArray = testArray.filter(testObj => testObj.Checkpoint === checkpoint)
            output[device][tsKey] = outTestArray
        })
    }

    return output
}

export function getUniqueCheckpointsForSpecificMap(data, device, mapName, timestamp) {
    const byMap = filterDataForSpecificMap(data, device, mapName, timestamp)
    return getUniqueCheckpoints(byMap, device)
}

export function filterDataBySpecifiedStats(data, device, statNames, timestamp) {
    const output = {
        [device]: {}
    }

    if (timestamp) {
        output[device][timestamp] = []
        data?.[device][timestamp].forEach((checkpoint) => {
            const out = {}
            Object.entries(checkpoint).forEach(([statKey, statVal]) => {
                if (statNames.includes(statKey))
                    out[statKey] = statVal
            })
            output[device][timestamp].push(out)
        })
    } else {
        Object.keys(data?.[device]).forEach((ts) => {
            output[device][ts] = []
            data?.[device][ts].forEach((checkpoint) => {
                const out = {}
                Object.entries(checkpoint).forEach(([statKey, statVal]) => {
                    if (statNames.includes(statKey))
                        out[statKey] = statVal
                })
                output[device][ts].push(out)
            })
        })
    }

    return output
}

export function getWorstCaseData(data, device, stat) {
    const output = []
    Object.entries(data?.[device]).forEach(([ts, arr]) => { // device's timestamps referring to array of checkpoint test objects
        let worstVal = HIGH_IS_BETTER[stat] ? Number.MAX_SAFE_INTEGER : 0
        let worstName = ""

        arr.forEach((cp) => {
            if ((HIGH_IS_BETTER[stat] && Number(cp[stat]) < worstVal) || (!HIGH_IS_BETTER[stat] && Number(cp[stat]) > worstVal)) {
                worstVal = Number(cp[stat])
                worstName = cp.Checkpoint
            }
        })

        output.push([worstName, worstVal, stat, ts])

    })
    return output
}

export function getUniqueCLs(data, device) {
    return Array.from(new Set(Object.keys(data?.[device]).map(t => Number(t.split('@')[0]))))
}


export function getTimestampsForCL(data, device, cl) {
    return Object.keys(data?.[device]).filter(t => {
        const thisCL = t.split('@')[0]
        return thisCL === cl
    }).map(t => t.split('@')[1])
}

export function getMaxStatValue(data, device, stat) {
    const max = Math.max(...Object.keys(data[device]).map((ts) => {
        return Math.max(...data[device][ts].map(x => Number(x[stat])))
    }))


    return max * 1.1
}