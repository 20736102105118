import {  HomeIcon, LocationIcon, GraphIcon, ClockIcon, LogIcon, CodespacesIcon, UploadIcon, TrashIcon, PackageIcon, SignOutIcon  } from "@primer/octicons-react";
import { BsBrush, BsGlobeAmericas, BsGpuCard, BsMemory, BsPlaystation, BsWindows, BsXbox } from 'react-icons/bs';
import raccoonIcon from '../res/raccoon-icon.png'
import singleplayerIcon from '../res/person-fill.png'
import splitscreenIcon from '../res/person-fill-splitscreen.png'
import { FiCpu } from "react-icons/fi";
import { P4_CHANGES_DISPLAYED_NUM, TARGET } from "./const";
export function countOccurrences(string, char) {
    let count = 0;
    for (let i = 0; i < string.length; i++) {
        if (string.charAt(i) === char) {
            count++;
        }
    }
    return count;
}

export function constrain(val, min, max) {
    return Math.min(Math.max(val, min), max)
}

export function CSVtoJSON(text) {
    const lines = text.split("\n")
    const headers = lines[0].split(",")
    const output = []
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",")
        const obj = {}
        for (let j = 0; j < headers.length; j++) {
            if (currentLine[j] && currentLine[j] !== "\r"){
                obj[headers[j]] = currentLine[j]
            }
        }
        if (Object.keys(obj).length > 0)
            output.push(obj)
    }
    return output
}

export function maxArrayLength(arrays) {
    let max = 0
    arrays.forEach(array => {
        if (array.length > max) max = array.length
    })
    return max
}

export function plusMinusText(stat) {
    if (stat > 0) return "+" + stat
    return stat
}

export function getIconByName(stat, className) {
    switch (stat) {
        case 'Dashboard': return <HomeIcon className={className} />
        case 'Timestamp': return <ClockIcon className={className} />
        case 'Tests': 
        case 'Change Lists': return <LogIcon className={className} />
        case 'Consoles': return <CodespacesIcon className={className} />
        case 'Stats': return <GraphIcon className={className} />
        case 'Local Test': return <UploadIcon className={className} />
        case 'Delete': return <TrashIcon className={className} />
        case 'Sign Out': return <SignOutIcon className={className} />
        case 'Map':
        case 'World': return <BsGlobeAmericas className={className} />
        case 'Locations':
        case 'Checkpoint': return <LocationIcon className={className} />
        case 'GameThreadTime': return <FiCpu className={className} />
        case 'GPUTime': return <BsGpuCard className={className} />
        case 'MemoryUsed': return <BsMemory className={className} />
        case 'DrawCalls': return <BsBrush className={className} />
        case 'PrimitivesDrawn': return <PackageIcon className={className} />
        case 'CreaturesTicked': return <img src={raccoonIcon} alt='' style={{width: 20, height: 20}} draggable={false} className={className} />
        case "PC":
            return <BsWindows className={className} />
        case "PS5":
            return <BsPlaystation className={className} />
        case "XSX":
            return <BsXbox className={className} />
        case "singleplayer":
            return <img src={singleplayerIcon} alt='' draggable={false} className={className} />
        case "splitscreen":
            return <img src={splitscreenIcon} alt='' draggable={false} className={className} />
        default: return null
    }
}

export function getDeviceColor(name) {
    switch (name) {
        case "PC":
            return "#00ADEF"
        case "PS5":
            return "#003791"
        case "XSX":
            return "#008542"
        default:
            return null
    }
}

export function getTargetColor(stat, stringValue) {
    const value = Number(stringValue)
    const keys = Object.keys(TARGET[stat]).map(k => Number(k)).reverse() // automatically sorted ascending
    
    for (let key of keys) {
        if (key <= value)
            return TARGET[stat][key]
    }
}

export function parseP4Info(p4Info) {
    // 
    let cleanedText = p4Info.split('\u0000').join('').replace('��', '')

    // only get limited changes so the page doesnt get flooded (and so the list still shows even if there are too many changes)
    // the test scripts now also have the limit built in
    const lines = cleanedText.split('\r\n').filter(e => e !== '').slice(0, P4_CHANGES_DISPLAYED_NUM + 1) // + 1 to account for the first line being the previous CL number
    let lineComponents = null

    if (lines.length > 1) {
        lineComponents = lines.slice(1).map(line =>
            {
                const changelistNum = String(line.match(/^Change (\d+) on/)[1])
                const username = `@${line.match(/by (.+?)@/)[1]}`
                const description = line.match(/'(.+?)'/)[1]
                return <a key={changelistNum} target="_blank" rel="noreferrer" className="hover-decoration-link" href={"https://swarm.raccoonlogic.com/changes/" + changelistNum}>{changelistNum + " - (" + username + ") " + description}</a>
            }
        )

        const lastCLComponent = <b key={"prev" + lines[0]}>Changes since {lines[0]}</b>

        lineComponents.unshift(lastCLComponent)
    }
    
    return lineComponents
}

export async function sleep(time) {
    // use promise to sleep
    return new Promise(resolve => setTimeout(resolve, time));
}

export function calculateInitialStateForSidebarSection(sectionName) {
    if (sectionName === "Dashboard")
        return true

    const url = window.location.pathname

    if (!url.includes("splitscreen") && sectionName === "Single Player")
        return true

    const playingMode = sectionName.toLowerCase().split(" ").join("").split("(").join("").split(")").join("")
    return url.includes(playingMode)
}