import React, { useState } from 'react'
import 'chart.js/auto'
import { Line } from 'react-chartjs-2';
import { filterDataBySpecifiedStats, filterDataForSpecificCheckpoint, getMaxStatValue, getUniqueRelevantStats } from '../js/dataUtils'
import { STAT_NICKNAMES } from '../js/const';
import { getIconByName } from '../js/utils';

function LocationStatsContainer(props) {
    const [selectedStat, setSelectedStat] = useState(props.searchParams.get('stat'))

    const getLocationStatChart = (data, device, stat, checkpoint) => {
        const relevantData = filterDataBySpecifiedStats(filterDataForSpecificCheckpoint(data, device, checkpoint), device, [stat])
        return <Line data={{
                labels: Object.keys(relevantData[device]).reverse(),
                datasets: [{
                    label: stat,
                    data: Object.values(relevantData[device]).map((t) => t[0]?.[stat]).reverse()
                }]
            
            }}  options={{
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false
                    }
                },
                layout: {
                    padding: 20
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        max: getMaxStatValue(data, device, stat)
                    }
                }
             }} />
        }

    return ( 
        <div className="stats-container">
            {getUniqueRelevantStats(props.data, props.currentDevice).map((stat) => 
                <div id={`${props.cp}`} className={"stats-chart" + (selectedStat === stat ? ' selected-chart' : '')}
                key={props.cp + ': ' + stat} onClick={() => {
                    if (selectedStat === stat) {
                        setSelectedStat(null)
                    } else {
                        setSelectedStat(stat)
                    }
                }}>
                <div className='stats-chart-header'>
                    {getIconByName(stat)}
                    <h3>{STAT_NICKNAMES[stat] || stat}</h3>
                    {STAT_NICKNAMES[stat] ? 
                        <div className="stats-charts-header-subtitle">{"(" + stat + ")"}</div>
                        : null
                    }
                </div>
                    {getLocationStatChart(props.data, props.currentDevice, stat, props.cp)}
                </div>
            )}
        </div>
     );
}

export default LocationStatsContainer;